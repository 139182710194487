import { useRouter } from 'next/router'
import getConfig from 'next/config'
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useRef,
} from 'react'

type PreviousPathContextState = {
  previousPath: string | null
  previousBrunchPath: string | null
}

const {
  publicRuntimeConfig: {
    BRUNCH_CONFIG: { BRUNCH_PARENT_EVENT_ID },
  },
} = getConfig()

const BRUNCH_BREADCRUMB_DEFAULT_PATH = '/events/es/barcelona'

const PreviousPathContext = createContext<PreviousPathContextState>({
  previousPath: null,
  previousBrunchPath: BRUNCH_BREADCRUMB_DEFAULT_PATH,
})

const usePreviousPathState = (): PreviousPathContextState => {
  const { asPath } = useRouter()

  const ref = useRef<string | null>(null)
  const brunchRef = useRef<string | null>(BRUNCH_BREADCRUMB_DEFAULT_PATH)

  useEffect(() => {
    ref.current = asPath

    // we only want to record the previous path that was not the brunch parent event page + query params
    // Ex: /events/${BRUNCH_PARENT_EVENT_ID}?ticketType=2-day
    if (!asPath.includes(BRUNCH_PARENT_EVENT_ID)) {
      brunchRef.current = asPath
    }
  }, [asPath])

  return { previousPath: ref.current, previousBrunchPath: brunchRef.current }
}

const PreviousPathContextProvider = ({
  children,
}: PropsWithChildren<object>) => {
  const previousPathState = usePreviousPathState()

  return (
    <PreviousPathContext.Provider value={previousPathState}>
      {children}
    </PreviousPathContext.Provider>
  )
}

const usePreviousPathContext = (): PreviousPathContextState =>
  useContext(PreviousPathContext)

export { usePreviousPathContext }
export default PreviousPathContextProvider
