import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { LoadingSpinner, Box, Alignment } from '@resident-advisor/design-system'
import getConfig from 'next/config'
import testIds from '@/enums/testIds'

const {
  publicRuntimeConfig: { LOADING_SPINNER_CONFIG },
} = getConfig()

const DefaultLoadingSpinner = ({ useDelay = true, backgroundColor }) => {
  // For components that load very quickly it looks strange to see the spinner flash
  const [delayed, setDelayed] = useState(useDelay)
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDelayed(false)
    }, LOADING_SPINNER_CONFIG?.LOADING_SPINNER_SHOW_DELAY ?? 0)
    return () => clearTimeout(timeout)
  }, [])

  // Often this component is used as a placeholder for the entire page markup - so this
  // will prevent it getting too large
  const loadingSize = {
    s: '40vw',
    m: '20vw',
    l: '15vw',
    xl: '10vw',
  }

  return (
    <Alignment
      justifyContent="center"
      backgroundColor={backgroundColor}
      data-testid={testIds.defaultLoadingSpinner}
    >
      <Box width={loadingSize} height={loadingSize} my={3}>
        {!delayed && <LoadingSpinner />}
      </Box>
    </Alignment>
  )
}

DefaultLoadingSpinner.propTypes = {
  backgroundColor: PropTypes.string,
  useDelay: PropTypes.bool,
}

export default DefaultLoadingSpinner
