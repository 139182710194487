import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import BEACON_ACTION from '@/enums/beacon-action'
import { useUserContext } from '@/context/UserContext'
import {
  useBeaconContext,
  defaultBeaconConfig,
} from '@/components/generic/helpscout'

function usePageBeacon() {
  const [loadedBeaconId, setLoadedBeaconId] = useState(null)
  const [destroyed, setDestroyed] = useState(false)
  const [showBeacon, setShowBeacon] = useState(false)
  const { query } = useRouter()
  const {
    initBeaconScript,
    callBeacon,
    helpScoutBeaconConfig,
    loading: beaconLoading,
  } = useBeaconContext()
  const { user, loading, isBot } = useUserContext()

  useEffect(() => {
    if (!loading && user && !isBot && !beaconLoading) {
      const { id, firstName, lastName, email, image } = user
      const data = {
        id,
        name: `${firstName} ${lastName}`,
        email,
        avatar: image,
      }
      callBeacon(BEACON_ACTION.identify, data)
    }
  }, [callBeacon, isBot, loading, user, beaconLoading])

  useEffect(() => {
    const id = helpScoutBeaconConfig?.helpScoutBeacon?.beaconId || null

    if (id) {
      setShowBeacon(true)
    }

    if (id === loadedBeaconId) {
      return
    }

    if (!id) {
      setShowBeacon(false)
      callBeacon(BEACON_ACTION.destroy)
      setLoadedBeaconId(null)
      setDestroyed(true)
      return
    }

    if (loadedBeaconId) {
      callBeacon(BEACON_ACTION.destroy)
      setDestroyed(true)
    }

    if (id && (loadedBeaconId || destroyed)) {
      callBeacon(BEACON_ACTION.init, id)
      callBeacon(BEACON_ACTION.config, defaultBeaconConfig)
    }

    initBeaconScript(id, defaultBeaconConfig)
    setLoadedBeaconId(id)
  }, [
    setShowBeacon,
    loadedBeaconId,
    setLoadedBeaconId,
    destroyed,
    setDestroyed,
    callBeacon,
    initBeaconScript,
    helpScoutBeaconConfig,
  ])

  // Automatically open the Beacon modal when a user lands on the page with `?contactSupport` in the URL
  useEffect(() => {
    if (
      showBeacon &&
      query &&
      Object.keys(query).includes(CONTACT_SUPPORT_QUERY_KEY) &&
      !beaconLoading
    ) {
      callBeacon(BEACON_ACTION.open)
    }
  }, [callBeacon, query, showBeacon, beaconLoading])

  return { showBeacon }
}

const CONTACT_SUPPORT_QUERY_KEY = 'contactSupport'

export { CONTACT_SUPPORT_QUERY_KEY }

export default usePageBeacon
