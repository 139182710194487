enum SupportedLocales {
  English = 'en',
  German = 'de',
  Spanish = 'es',
  MexicanSpanish = 'es-MX',
  French = 'fr',
  Italian = 'it',
  Japanese = 'ja',
  Dutch = 'nl',
  Portuguese = 'pt',
  BrazilianPortuguese = 'pt-BR',
  Polish = 'pl',
}

export default SupportedLocales
