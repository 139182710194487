import { useEffect, useRef } from 'react'
import { IntlProvider } from 'react-intl'
import PropTypes from 'prop-types'
import getLanguage from 'lib/getLanguage'
import { useI18nContext, i18nActions } from '@/context/I18nContext'

const WithIntl = ({ children }) => {
  const { state, dispatch } = useI18nContext()
  const currentLocale = useRef(state.locale)

  useEffect(() => {
    const getMessages = async (updatedLocale) => {
      const updatedMessages = await getLanguage(updatedLocale)

      if (updatedMessages) {
        dispatch({ type: i18nActions.SET_MESSAGES, value: updatedMessages })
        currentLocale.current = updatedLocale
      }
    }

    if (state.locale && state.locale !== currentLocale.current) {
      getMessages(state.locale)
    }
  }, [dispatch, state])

  return (
    <IntlProvider locale={state.locale} messages={state.messages}>
      {children}
    </IntlProvider>
  )
}

WithIntl.propTypes = {
  children: PropTypes.any.isRequired,
}

export default WithIntl
