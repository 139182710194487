import { AdSlot } from 'react-dfp'
import PropTypes from 'prop-types'
import { useRef } from 'react'
import { useAdTargetingContext } from '@/context/AdTargetingContext'
import ErrorBoundary from '@/components/generic/error-boundary'
import variants, { withName } from './variants'
import useShowAd from './useShowAd'
import AdMarkup from './AdMarkup'

const noop = () => {
  // do nothing
}

const getAdHeight = (node) => {
  return node?.getBoundingClientRect().height
}

const getSizes = (sizeMapping) =>
  sizeMapping?.reduce((acc, x) => {
    if (Array.isArray(x?.sizes)) {
      return [...acc, ...x.sizes]
    }
    return [...acc, x?.sizes]
  }, [])

const Ad = ({ variant, onSlotRender = noop, ...props }) => {
  const { show, setShow, loading: showLoading } = useShowAd(variant.show)
  const {
    state: { loading, ...targeting },
  } = useAdTargetingContext()
  const measureRef = useRef()

  const onRender = (values) => {
    if (values?.isEmpty) {
      setShow(false)
    }

    if (typeof onSlotRender === 'function') {
      const height = getAdHeight(measureRef.current)
      onSlotRender({ ...values, height })
    }
  }

  if (showLoading || !show) {
    return null
  }

  return (
    // Error boundary required for adblockers messing with the ads library
    <ErrorBoundary silent>
      <AdMarkup
        data-testid={`ad-${variant.adUnit}`}
        justifyContent="center"
        alignItems="center"
        display={variant.display}
        bg={variant.color}
        ref={measureRef}
        className={Ad.adSlotClass} // Ensures the container is hidden by adblockers based on its class https://easylist-downloads.adblockplus.org/easylist.txt
        {...props}
        {...variant.reservedSizes}
      >
        {!loading && (
          <AdSlot
            onSlotRender={onRender}
            targetingArguments={targeting}
            adUnit={variant.adUnit}
            sizeMapping={variant.sizeMapping}
            sizes={variant.sizes || getSizes(variant.sizeMapping)}
          />
        )}
      </AdMarkup>
    </ErrorBoundary>
  )
}

Ad.propTypes = {
  variant: PropTypes.shape({
    display: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        s: PropTypes.string,
        m: PropTypes.string,
        l: PropTypes.string,
      }),
    ]),
    show: PropTypes.shape({
      s: PropTypes.bool,
      m: PropTypes.bool,
      l: PropTypes.bool,
    }),
    adUnit: PropTypes.string,
    sizes: PropTypes.array,
    sizeMapping: PropTypes.array,
    reservedSizes: PropTypes.object,
    color: PropTypes.string,
  }).isRequired,
  onSlotRender: PropTypes.func,
}

Ad.variants = variants
Ad.withName = withName
Ad.adSlotClass = 'adSlot'

export default Ad
