import arrayHasData from 'lib/arrayHasData'
import { useEffect, useCallback } from 'react'
import getConfig from 'next/config'
import BEACON_ACTION from '@/enums/beacon-action'
import { useBeaconContext } from './BeaconContext'

const { publicRuntimeConfig } = getConfig()

const PREFILL_FIELD_IDS = {
  EventID: publicRuntimeConfig.HELPSCOUT_CONFIG.CUSTOM_FIELDS.EVENT_ID,
  OrderID: publicRuntimeConfig.HELPSCOUT_CONFIG.CUSTOM_FIELDS.ORDER_ID,
}

const usePrefillBeacon = (data) => {
  const prefill = usePrefillBeaconCallback()

  useEffect(() => {
    prefill(data)
  }, [prefill, data])
}

const usePrefillBeaconCallback = () => {
  const { callBeacon, helpScoutBeaconConfig } = useBeaconContext()

  const prefill = useCallback(
    (data) => {
      if (!arrayHasData(data)) {
        return
      }

      const supportedFields =
        helpScoutBeaconConfig?.helpScoutBeacon?.customFields?.map(
          (f) => f.fieldId
        )
      if (!arrayHasData(supportedFields)) {
        // No Beacon, or custom fields aren't enabled
        return
      }

      const filteredFields = data.filter((f) => supportedFields.includes(f.id))

      if (!arrayHasData(filteredFields)) {
        return
      }

      callBeacon(BEACON_ACTION.once, BEACON_ACTION.open, () =>
        callBeacon(BEACON_ACTION.prefill, {
          fields: filteredFields,
        })
      )
    },
    [callBeacon, helpScoutBeaconConfig]
  )
  return prefill
}

export { PREFILL_FIELD_IDS, usePrefillBeaconCallback }
export default usePrefillBeacon
