import featureSwitches from '@/enums/feature-switches'

const TRACKING_EXPERIMENT = {
  [featureSwitches.promoterTicketFeeTypeExperiment]: {
    name: 'Promoter ticket fee type',
    // https://eu.app.unleash-hosted.com/eubb7016/projects/default/features/promoterTicketFeeTypeExperiment/variants
    variants: {
      bookingFee: 'bookingFee',
      serviceFee: 'serviceFee',
    },
  },
  [featureSwitches.hideInterestedCount]: {
    name: 'Hide interested (roll call) count',
    // https://eu.app.unleash-hosted.com/eubb7016/projects/default/features/hideInterestedCount/variants
    variants: {
      on: 'on',
      off: 'off',
    },
  },
  [featureSwitches.testFeature]: {
    name: 'Feature for tests only',
    variants: {
      on: 'on',
      off: 'off',
    },
  },
}

export default TRACKING_EXPERIMENT
