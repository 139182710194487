import { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import {
  Button,
  Icon,
  CloseIcon,
  CommentIcon,
  SearchIcon,
  QuestionMarkIcon,
} from '@resident-advisor/design-system'
import { useIntl } from 'react-intl'
import { useRouter } from 'next/router'
import Tracking, { TRACKING_EVENT } from 'scripts/tracking'
import { useThemeContext } from '@/hooks/useThemeContext'
import dict from '@/messages/dict'
import BEACON_ACTION from '@/enums/beacon-action'
import PLAYWRIGHT_TEST_IDS from '@/enums/playwright-test-ids'
import { useBeaconContext } from './BeaconContext'

const BeaconButton = () => {
  const [info, setInfo] = useState(false)

  const intl = useIntl()
  const theme = useThemeContext()
  const { asPath } = useRouter()
  const { callBeacon, shouldShowBeaconButton, open } = useBeaconContext()

  callBeacon(BEACON_ACTION.once, BEACON_ACTION.ready, () =>
    setInfo(callBeacon(BEACON_ACTION.info))
  )
  callBeacon(BEACON_ACTION.on, BEACON_ACTION.open, () => {
    Tracking.trackMixpanel(TRACKING_EVENT.helpscoutBeaconClick, {
      'Beacon ID': info?.beaconId,
      'Beacon Name': info?.beaconName,
      Page: asPath,
    })
  })

  const display = info?.config?.display
  const beaconIcon = getIcon(display?.iconImage)
  const icon = open && beaconIcon ? CloseIcon : beaconIcon
  const text =
    open && display?.text
      ? intl.formatMessage(dict.close)
      : intl.formatMessage(dict.help)

  if (!info || open || !shouldShowBeaconButton) return null

  return (
    <StyledButton
      onClick={() => callBeacon(BEACON_ACTION.toggle)}
      display={display}
      zIndex={theme.zIndex.beaconButton}
      data-pw-test-id={PLAYWRIGHT_TEST_IDS.beaconButton}
    >
      {display.textAlign === 'left' && text}
      {!icon && !text && (
        <StyledIcon Component={QuestionMarkIcon} display={display} />
      )}
      {icon && <StyledIcon Component={icon} display={display} />}
      {display.textAlign === 'right' && text}
    </StyledButton>
  )
}

const StyledButton = styled(Button)`
  position: fixed;
  z-index: ${(props) => props.zIndex};
  bottom: 20px;

  ${({ display }) =>
    display?.position === 'left'
      ? css`
          left: 20px;
        `
      : css`
          right: 20px;
        `};

  ${({ display }) =>
    !display?.text &&
    css`
      padding: 0px;

      &:hover {
        padding: 0px;
      }
    `}
`

const StyledIcon = styled(Icon)`
  ${({ display }) =>
    display?.textAlign === 'left'
      ? css`
          padding-left: 5px;
        `
      : css`
          padding-right: 5px;
        `}

  ${({ display }) =>
    !display?.text &&
    css`
      padding: 0px;
    `}
`

const getIcon = (iconImage) => {
  switch (iconImage) {
    case 'message':
      return CommentIcon
    case 'search':
      return SearchIcon
    case 'question':
      return QuestionMarkIcon
    default:
      return null
  }
}

const useHiddenBeaconButton = () => {
  const { hideBeaconButton, showBeaconButton } = useBeaconContext()

  useEffect(() => {
    // The beacon is being rendered on this page, but we want to hide the button
    // (they'll open it by clicking the Button in <ContactRATicketSupport/>)
    hideBeaconButton()

    return () => {
      // If they navigate to another page, reset the hiding of the beacon button
      showBeaconButton()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export { useHiddenBeaconButton }
export default BeaconButton
