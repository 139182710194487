import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import animation from '@/themes/animation'

const FadeInBackground = ({
  backgroundOpacity,
  transitions,
  children,
  ...props
}) => (
  <motion.div
    variants={{
      open: {
        backgroundColor: `rgba(0,0,0,${backgroundOpacity})`,
        transition: transitions.open,
      },
      closed: {
        backgroundColor: `rgba(0,0,0,0)`,
        transition: transitions.closed,
      },
    }}
    {...props}
  >
    {children}
  </motion.div>
)

FadeInBackground.propTypes = {
  backgroundOpacity: PropTypes.number,
  transitions: PropTypes.shape({
    open: PropTypes.shape(),
    closed: PropTypes.shape(),
  }),
  children: PropTypes.node.isRequired,
}

FadeInBackground.defaultProps = {
  backgroundOpacity: 1,
  transitions: animation.default,
}

export default FadeInBackground
