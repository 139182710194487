'use client'

import { useReportWebVitals } from 'next/web-vitals'
import { logger } from 'lib/logger'
import { useRouter } from 'next/router'
import getConfig from 'next/config'
import useIsMobile from '@/hooks/useIsMobile'

const CoreWebVitalsLogging = () => {
  const { pathname } = useRouter()
  const isMobile = useIsMobile()

  useReportWebVitals((metrics) => {
    const coreWebVitalsEnabledPathnameRegex = new RegExp(
      CORE_WEB_VITALS_ENABLED_PATHNAME_REGEX
    )

    const shouldReportCoreWebVitals =
      coreWebVitalsEnabledPathnameRegex.test(pathname)

    if (shouldReportCoreWebVitals) {
      // remove "entries" key as it is not needed
      const collectedMetrics = { ...metrics, entries: undefined }

      logger.info(
        {
          metrics: collectedMetrics,
          page: pathname,
          device: isMobile ? 'mobile' : 'desktop',
        },
        'Core Web Vitals'
      )
    }
  })

  return null
}

const {
  publicRuntimeConfig: { CORE_WEB_VITALS_ENABLED_PATHNAME_REGEX },
} = getConfig()

export default CoreWebVitalsLogging
