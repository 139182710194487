import BEACON_ACTION from '@/enums/beacon-action'
import { usePrefillBeaconCallback } from './usePrefillBeacon'
import { useBeaconContext } from './BeaconContext'

const usePrefillBeaconAndOpen = () => {
  const { callBeacon, open } = useBeaconContext()
  const beaconPrefill = usePrefillBeaconCallback()

  const openBeacon = (prefillData) => {
    if (open) {
      callBeacon(BEACON_ACTION.close)
    }
    beaconPrefill(prefillData)
    callBeacon(BEACON_ACTION.open)
  }
  return openBeacon
}

export default usePrefillBeaconAndOpen
