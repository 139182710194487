import React from 'react'
import App from 'next/app'
import { ThemeProvider } from 'styled-components'
import Head from 'next/head'
import { ApolloProvider } from '@apollo/client'
import 'focus-visible'
import { withApollo } from 'lib/apollo/withApollo'
import { DefaultSeo } from 'next-seo'
import appInitialProps from 'src/server/app-initial-props'
import { TrackingWrapper, GtagScript, MixpanelProvider } from 'scripts/tracking'
import {
  bindFixScrollPosition,
  unbindFixScrollPosition,
} from 'lib/fixScrollPosition'
import { captureError } from 'lib/logger'
import captureTextReplacementErrors from 'scripts/captureTextReplacementErrors'
import RavelinScript from 'scripts/ravelin'
import RivetedScript from 'scripts/riveted'
import { light } from '@/themes'
import { I18nProvider } from '@/context/I18nContext'
import { PersonalizationProvider } from '@/context/PersonalizationContext'
import { ContentLanguageProvider } from '@/context/ContentLanguageContext'
import { DomainProvider } from '@/context/DomainContext'
import ServerTimeContext from '@/context/ServerTimeContext'
import { UserProvider } from '@/context/UserContext'
import { FeatureSwitchesProvider } from '@/context/FeatureSwitchesContext'
import WithIntl from '@/components/shared/with-intl'
import { MenuContextProvider } from '@/context/MenuContext'
import { AnimationContextProvider } from '@/context/AnimationContext'
import { PageErrorBoundary } from '@/components/generic/error-boundary'
import TopLoadingBar from '@/components/navigation/top-loading-bar'
import { AdTargetingContextProvider } from '@/context/AdTargetingContext'
import { AdContextProvider } from '@/components/generic/ad'
import { Beacons } from '@/components/generic/helpscout'
import StyledComponentPropsFilter from '@/components/generic/styled-components-props-filter'
import { StickyContextProvider } from '@/context/StickyContext'
import RequestLogging from '@/components/request-logging'
import { DateFnsLocaleProvider } from '@/hooks/useDateFnsLocale/useDateFnsLocale'
import PreviousPathContextProvider from '@/context/PreviousPathContext'
import PerformanceContextProvider from '@/context/PerformanceContext'
import CoreWebVitalsLogging from '@/components/performance/core-web-vitals'
import SEO from '../next-seo.config'
import 'intersection-observer'
import 'react-image-crop/dist/ReactCrop.css'
import 'public/static/fonts.css'
import 'public/static/global.css'
import 'public/static/featureStyle.scss'
import 'public/static/braintreeDropinStyle.scss'

const isClientSide = typeof window !== 'undefined'

// https://github.com/welldone-software/why-did-you-render
// Toggle the option to true to log for all components
// For a specific component, inside the component definition, add YourComponent.whyDidYouRender = true
if (process.env.NODE_ENV === 'development' && isClientSide) {
  /* eslint-disable global-require */
  const whyDidYouRender = require('@welldone-software/why-did-you-render')
  whyDidYouRender(React, { trackAllPureComponents: false })
}

if (process.env.NODE_ENV === 'development' && isClientSide) {
  // Logs Core Web Vitals debug info to the console https://www.npmjs.com/package/web-vitals
  // Only intended for local development.
  const debugCLS = !!process.env.NEXT_PUBLIC_DEBUG_CLS
  const debugFID = !!process.env.NEXT_PUBLIC_DEBUG_FID
  const debugLCP = !!process.env.NEXT_PUBLIC_DEBUG_LCP
  const debugINP = !!process.env.NEXT_PUBLIC_DEBUG_INP
  const debugTTFB = !!process.env.NEXT_PUBLIC_DEBUG_TTFB
  const debugFCP = !!process.env.NEXT_PUBLIC_DEBUG_FCP

  if (debugCLS || debugFID || debugLCP || debugINP || debugTTFB || debugFCP) {
    const { onCLS, onFID, onLCP, onINP, onTTFB, onFCP } = require('web-vitals')

    // eslint-disable-next-line no-console
    const log = console.log

    if (debugCLS) onCLS(log, { reportAllChanges: true })
    if (debugFID) onFID(log, { reportAllChanges: true })
    if (debugLCP) onLCP(log, { reportAllChanges: true })
    if (debugINP) onINP(log, { reportAllChanges: true })
    if (debugTTFB) onTTFB(log, { reportAllChanges: true })
    if (debugFCP) onFCP(log, { reportAllChanges: true })
  }
}

class AppComponent extends App {
  static async getInitialProps(appContext) {
    return appInitialProps(appContext)
  }

  componentDidMount() {
    bindFixScrollPosition()
    captureTextReplacementErrors()
  }

  componentWillUnmount() {
    unbindFixScrollPosition()
  }

  componentDidCatch(error, errorInfo) {
    captureError(error, errorInfo)
  }

  render() {
    const {
      Component,
      pageProps,
      apolloClient,
      locale,
      messages,
      asPath,
      areaId,
      eventsAreaId,
      serverTime,
      isBot,
      contentLanguage,
      contentLanguageCookie,
      currentDomain,
      featureSwitches,
      isServerSideRender,
      hasRefreshToken,
    } = this.props

    return (
      <DomainProvider currentDomain={currentDomain}>
        <PreviousPathContextProvider>
          <RequestLogging initialSSR={isServerSideRender}>
            <AdContextProvider>
              <ServerTimeContext.Provider value={serverTime}>
                <ApolloProvider client={apolloClient}>
                  <FeatureSwitchesProvider
                    toggles={featureSwitches?.values || []}
                    context={featureSwitches?.initialContext || {}}
                  >
                    <MixpanelProvider>
                      <PerformanceContextProvider>
                        <RavelinScript />
                        <GtagScript />
                        <RivetedScript />

                        <Beacons>
                          <I18nProvider messages={messages} locale={locale}>
                            <WithIntl>
                              <AdTargetingContextProvider>
                                <UserProvider
                                  isBot={isBot}
                                  hasRefreshToken={hasRefreshToken}
                                >
                                  <PersonalizationProvider
                                    areaId={areaId}
                                    eventsAreaId={eventsAreaId}
                                  >
                                    <ContentLanguageProvider
                                      contentLanguage={contentLanguage}
                                      contentLanguageCookie={
                                        contentLanguageCookie
                                      }
                                    >
                                      <DateFnsLocaleProvider>
                                        <StyledComponentPropsFilter>
                                          <ThemeProvider theme={light}>
                                            <MenuContextProvider>
                                              <AnimationContextProvider>
                                                <TrackingWrapper>
                                                  <StickyContextProvider>
                                                    <DefaultSeo {...SEO} />
                                                    <Head>
                                                      <meta
                                                        name="viewport"
                                                        content="width=device-width, initial-scale=1.0"
                                                      />
                                                      <link
                                                        rel="preload"
                                                        href="/static/AlternateGothicProCyrillic.woff2"
                                                        as="font"
                                                        crossOrigin="anonymous"
                                                        type="font/woff2"
                                                      />
                                                      <link
                                                        rel="preload"
                                                        href="/static/RobotoMono-Regular.woff2"
                                                        as="font"
                                                        crossOrigin="anonymous"
                                                        type="font/woff2"
                                                      />
                                                    </Head>
                                                    <TopLoadingBar />
                                                    <PageErrorBoundary>
                                                      <CoreWebVitalsLogging />
                                                      <Component
                                                        asPath={asPath}
                                                        {...pageProps}
                                                      />
                                                    </PageErrorBoundary>
                                                  </StickyContextProvider>
                                                </TrackingWrapper>
                                              </AnimationContextProvider>
                                            </MenuContextProvider>
                                          </ThemeProvider>
                                        </StyledComponentPropsFilter>
                                      </DateFnsLocaleProvider>
                                    </ContentLanguageProvider>
                                  </PersonalizationProvider>
                                </UserProvider>
                              </AdTargetingContextProvider>
                            </WithIntl>
                          </I18nProvider>
                        </Beacons>
                      </PerformanceContextProvider>
                    </MixpanelProvider>
                  </FeatureSwitchesProvider>
                </ApolloProvider>
              </ServerTimeContext.Provider>
            </AdContextProvider>
          </RequestLogging>
        </PreviousPathContextProvider>
      </DomainProvider>
    )
  }
}

export default withApollo({
  ssr: true,
})(AppComponent)
