import { createHttpLink as createApolloHttpLink } from '@apollo/client'
import getConfig from 'next/config'
import getUriBranch from 'lib/getUriBranch'
import ENVIRONMENT from '@/enums/environment'

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig()

const createHttpLink = (isBrowser, isProduction) => {
  const graphQLUri = getUriBranch(
    isBrowser,
    isProduction,
    publicRuntimeConfig.GRAPHQL_URL,
    serverRuntimeConfig.INTERNAL_GRAPHQL_URL
  )
  /**
   * We do this because locally we use cookies from different ports
   * RA-Next is located in localhost:3000 and ra-be is located in localhost:5000
   * this means that we cannot use same origin and thus include. In the case of production
   * and uat. RA-BE is (uat.)ra.co and ra next is (uat.)ra.co so we can use same-origin
   */
  const isLocal = publicRuntimeConfig.ENVIRONMENT === ENVIRONMENT.local
  const credentials = isLocal ? 'include' : 'same-origin'

  return createApolloHttpLink({
    uri: graphQLUri,
    credentials,
    fetch,
  })
}

export default createHttpLink
