import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import animation from '@/themes/animation'

const EaseInFromRightSide = ({ transitions, children, ...props }) => (
  <motion.div
    variants={{
      open: {
        x: '0%',
        transition: transitions.open,
      },
      closed: {
        x: '100%',
        transition: transitions.closed,
      },
    }}
    {...props}
  >
    {children}
  </motion.div>
)

EaseInFromRightSide.propTypes = {
  transitions: PropTypes.shape({
    open: PropTypes.shape(),
    closed: PropTypes.shape(),
  }),
  children: PropTypes.node.isRequired,
}

EaseInFromRightSide.defaultProps = {
  transitions: animation.default,
}

export default EaseInFromRightSide
