import isPropValid from '@emotion/is-prop-valid'
import { StyleSheetManager } from 'styled-components'

const StyledComponentPropsFilter = ({ children }: React.PropsWithChildren) => {
  /**
   * In styled-components v6 shouldForwardProps is not provided by default
   * in cases where we have custom props that are used in styled-components we can use transient props
   * however in cases where styled-system props are used (e.g. pb y etc.) we need to use isPropValid in order not to pass them to the DOM
   * see https://styled-components.com/docs/faqs#shouldforwardprop-is-no-longer-provided-by-default
   */
  const shouldForwardProp = (propName, target) => {
    if (typeof target === 'string') {
      // For HTML elements, forward the prop if it is a valid HTML attribute
      return isPropValid(propName)
    }

    // for other components, forward the prop
    return true
  }

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      {children}
    </StyleSheetManager>
  )
}

export default StyledComponentPropsFilter
