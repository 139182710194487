import PropTypes, { InferProps } from 'prop-types'
import { motion } from 'framer-motion'
import animation from '@/themes/animation'

const EaseInFromLeftSide: React.FC<EaseInFromLeftSideProps> = ({
  transitions = animation.default,
  onAnimationStart,
  onAnimationComplete,
  children,
  ...props
}) => (
  <motion.div
    variants={{
      open: {
        x: '0%',
        transition: transitions.open,
      },
      closed: {
        x: '-100%',
        transition: transitions.closed,
      },
    }}
    onAnimationStart={onAnimationStart}
    onAnimationComplete={onAnimationComplete}
    {...props}
  >
    {children}
  </motion.div>
)

const EaseInFromLeftSidePropTypes = {
  transitions: PropTypes.shape({
    open: PropTypes.object,
    closed: PropTypes.object,
  }),
  onAnimationStart: PropTypes.func,
  onAnimationComplete: PropTypes.func,
  children: PropTypes.node.isRequired,
}

EaseInFromLeftSide.propTypes = EaseInFromLeftSidePropTypes
type EaseInFromLeftSideProps = InferProps<typeof EaseInFromLeftSidePropTypes>

export default EaseInFromLeftSide
