import { gql } from '@apollo/client'
import PROMOTER_FRAGMENT from '@/queries/fragments/PromoterFragment'

const GET_USER_QUERY = gql`
  query GET_USER_QUERY {
    user {
      id
      username
      image
      locale
      firstName
      lastName
      newMessagesCount
      newEventRevisionsCount
      followedProfilesCount
      email
      permissions
      dateRegistered
      registrationSource
      dateOfBirth
      area {
        id
        name
        urlName
        country {
          id
          name
          urlCode
        }
      }
      adTargeting {
        targetId
        campaignCode
      }
      managedProfiles {
        hasArtists
        hasLabels
        hasPromoters
      }
      managedArtists {
        id
      }
      managedPromoters {
        ...promoterFragment
        features
      }
    }
  }
  ${PROMOTER_FRAGMENT}
`

export default GET_USER_QUERY
