/* eslint-disable @next/next/no-html-link-for-pages */
import { Alignment, Icon, RAIcon, Text } from '@resident-advisor/design-system'
import { useRouter } from 'next/router'
import { logger } from 'lib/logger'
import Seo from '@/components/generic/seo/Seo'

const InternalServerError = () => {
  const router = useRouter()

  logger.error('500 Internal Server Error', {
    url: router?.asPath,
    page: router?.pathname,
  })

  return (
    <>
      <Seo title="Unexpected error ⟋ RA" />
      <Alignment
        height="100vh"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Icon size={128} Component={RAIcon} color="primary" />
        <Text>
          An unexpected error occurred, go back to the <a href="/">Homepage</a>
        </Text>
      </Alignment>
    </>
  )
}

export default InternalServerError
