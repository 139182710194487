import { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { light, dark } from '@/themes'

export const useThemeContext = (shade) => {
  const theme = useContext(ThemeContext)

  if (shade) {
    if (shade === 'light') {
      return light
    }

    if (shade === 'dark') {
      return dark
    }
  }

  return theme
}
